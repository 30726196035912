import { t } from '@lingui/macro';
import CalculateEqSavingsParams from '../../types/EqCalculator/CalculateEqSavingsParams.interface';
import CalculateEqSavingsResults from '../../types/EqCalculator/CalculateEqSavingsResults.interface';
import EqBenefitsDisplayInfo from '../../types/EqCalculator/EqBenefitsDisplayInfo.interface';
import EqBrandEnvImpact from '../../types/EqCalculator/EqBrandEnvImpact.interface';
import EqEnvImpactEquivalent from '../../types/EqCalculator/EqEnvImpactEquivalent.interface';
import EqEnvImpactSynthesis from '../../types/EqCalculator/EqEnvImpactSynthesis.interface';
import EqImpactsDisplayInfo from '../../types/EqCalculator/EqImpactsDisplayInfo.interface';
import EqUiElement from '../../types/EqCalculator/EqUiElement.interface';
import EqUiElementCaseVal from '../../types/EqCalculator/EqUiElementCaseVal.enum';
import EqUiElementName from '../../types/EqCalculator/EqUiElementName.enum';

export const BENEFITS_LIST_EL_NAME_PREFIX = 'KeyBenefit';

export const getTotalWeightFromParams = (
  params: CalculateEqSavingsParams
): number => {
  const weights = params.brands.map((brand) => brand.value);

  const totalWeight = weights.reduce(
    (accumulator, currentWeight) => accumulator + parseFloat(currentWeight),
    0
  );

  return totalWeight;
};

export const getEnvImpactEquiv = (
  brandEnvImpact: EqBrandEnvImpact
): EqEnvImpactEquivalent => ({
  type: brandEnvImpact.type,
  image: brandEnvImpact.co2EqImage,
  amount: parseFloat(brandEnvImpact.co2EqResult),
});

export const getEnvImpactSynthesis = (
  calculatedSavings: CalculateEqSavingsResults
): EqEnvImpactSynthesis => {
  let sappiCo2Footprint = 0;
  let industryAvgCo2Footprint = 0;
  const equivalents: {
    [key: string]: EqEnvImpactEquivalent;
  } = {};

  Object.values(calculatedSavings.reductionTypes).forEach(
    (reductionType, index) => {
      Object.values(reductionType).forEach((currentBrandEnvImpact) => {
        if (!equivalents[currentBrandEnvImpact.type]) {
          equivalents[currentBrandEnvImpact.type] = {
            type: currentBrandEnvImpact.type,
            image: currentBrandEnvImpact.co2EqImage,
            amount: 0,
          };
        }
        equivalents[currentBrandEnvImpact.type].amount += parseFloat(
          currentBrandEnvImpact.co2EqResult
        );

        if (index === 0) {
          sappiCo2Footprint += parseFloat(
            currentBrandEnvImpact.sappiCo2Footprint
          );
          industryAvgCo2Footprint += parseFloat(
            currentBrandEnvImpact.industryCo2Footprint
          );
        }
      });
    }
  );

  return {
    sappiCo2Footprint,
    industryAvgCo2Footprint,
    equivalents: Object.values(equivalents),
  };
};

export const getUiElementByName = (
  uiElements: EqUiElement[],
  name: EqUiElementName
): EqUiElement | undefined => {
  return uiElements.find((uiElement) => uiElement.name === name);
};

export const applyCaseTransformation = (
  text: string,
  caseVal: string
): string => {
  const shouldTransform =
    caseVal.toLowerCase() === EqUiElementCaseVal.UPPER.toLowerCase();

  return shouldTransform ? text.toUpperCase() : text;
};

export const getTextFromUiElement = (
  uiElement?: EqUiElement,
  applyTransformations = true
): string | undefined => {
  if (uiElement) {
    let { text } = uiElement;

    if (applyTransformations) {
      text = applyCaseTransformation(text, uiElement.caseVal);
    }

    return text;
  }

  return undefined;
};

export const getUiElementText = (
  uiElements: EqUiElement[],
  name: EqUiElementName,
  applyTextTransformations = true
): string | undefined => {
  const element = getUiElementByName(uiElements, name);

  return getTextFromUiElement(element, applyTextTransformations);
};

export const insertEpaCalculatorLink = (
  text: string,
  epaCalculatorUrl: string
): string => {
  const epaCalcTextRaw = 'EPA emissions calculator';
  const epaCalcTextTranslated = t`EPA emissions calculator`;

  if (text.toLowerCase().indexOf(epaCalcTextRaw.toLowerCase()) !== -1) {
    return text.replace(
      epaCalcTextRaw,
      `<a class='link' href='${epaCalculatorUrl}' target='_blank' rel="noreferrer">${epaCalcTextTranslated}</a>`
    );
  }
  return text;
};

export const getImpactsDisplayInfoFromUiElements = (
  uiElements: EqUiElement[]
): EqImpactsDisplayInfo | undefined => {
  let impactStatement = getUiElementText(
    uiElements,
    EqUiElementName.IMPACT_STATEMENT
  );
  const carEmissionsEquivLabel = getUiElementText(
    uiElements,
    EqUiElementName.IMPACT_CAR
  );
  const carGasolineEquivLabel = getUiElementText(
    uiElements,
    EqUiElementName.IMPACT_GAS
  );
  const scope1Footnote = getUiElementText(
    uiElements,
    EqUiElementName.IMPACT_SCOPE_1
  );
  const scope2Footnote = getUiElementText(
    uiElements,
    EqUiElementName.IMPACT_SCOPE_2
  );
  const industryAvgFootnote = getUiElementText(
    uiElements,
    EqUiElementName.IMPACT_INDUSTRY_AVERAGE
  );
  const epaCalculatorUrl = getUiElementText(
    uiElements,
    EqUiElementName.IMPACT_EPA_CALCULATOR
  );

  if (impactStatement && epaCalculatorUrl) {
    impactStatement = insertEpaCalculatorLink(
      impactStatement,
      epaCalculatorUrl
    );
  }

  if (
    impactStatement &&
    carEmissionsEquivLabel &&
    carGasolineEquivLabel &&
    scope1Footnote &&
    scope2Footnote &&
    industryAvgFootnote
  ) {
    return {
      impactStatement,
      carEmissionsEquivLabel,
      carGasolineEquivLabel,
      scope1Footnote,
      scope2Footnote,
      industryAvgFootnote,
    };
  }

  return undefined;
};

export const insertSappiLink = (text: string, sappiUrl: string): string => {
  const sappiText = 'sappi.com';

  if (text.toLowerCase().indexOf(sappiText.toLowerCase()) !== -1) {
    return text.replace(
      sappiText,
      `<a class='link' href='${sappiUrl}' target='_blank' rel="noreferrer">${sappiText}</a>`
    );
  }

  return text;
};

export const getBenefitsDisplayInfoFromUiElements = (
  uiElements: EqUiElement[]
): EqBenefitsDisplayInfo => {
  const title = getUiElementText(uiElements, EqUiElementName.BENEFITS_TITLE);
  const benefits: string[] = [];

  uiElements.forEach((uiElement) => {
    if (uiElement.name !== EqUiElementName.BENEFITS_TITLE) {
      let text = getTextFromUiElement(uiElement);

      if (text && uiElement.name.toLowerCase().indexOf('ybenefit') !== -1) {
        text = insertSappiLink(text, 'https://www.sappi.com');
      }

      if (text) {
        benefits.push(text);
      }
    }
  });

  return {
    title,
    benefits,
  };
};
